.SIM-card {
  width: 400px;
  height: 175px;
  vertical-align: top;
  margin: 10px;
  display: inline-block;
}

.SIM-card-usage-total {
  font-size: 1.5em;
}

.usage-label {
  display: block;
  font-size: 12px;
  font-weight: 300;
}

.total-usage-summary {
  margin: 0 0 25px 0px;
  display: inline-block;
}

.usage-totals-data-container {
  margin-top: 10px;
}

.no-usage-data-container {
  margin: 20px 0 0 0;
}

.no-usage-data-container h4 {
  margin: 6px 0 0 0;
  font-weight: 400;
}

.usage-data-item {
  float: left;
  margin: 10px;
  border-right: 1px solid #d0d0d0;
  padding: 0 15px 0px 0;
}

.usage-data-item:last-child {
  margin-right: 0;
  border: none;
}

.service-status {
  float: right;
}

.service-status-label {
  text-transform: capitalize;
  margin-left: 2px;
}

.service-status-icon {
  vertical-align: text-bottom;
}

.iccid {
  color: #666;
}

.danger {
  color: #c23030;
}
.success {
  color: #0d8050;
}
.none {
  color: #666;;
}
.warning {
  color: rgb(148, 96, 0);
}
