.loading-indicator-container {
  margin: 0 auto;
  margin-top: 20%;
  padding-bottom: 50%;
  width: 500px;
}

.loading-indicator {
    margin: 100px auto 0;
    width: 150px;
    text-align: center;
  }
  
  .loading-indicator > div {
    width: 20px;
    height: 20px;
    background-color: #000000;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .loading-indicator .bounce1 {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
  }
  
  .loading-indicator .bounce2 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .loading-indicator .bounce3 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  .loading-indicator-text {
    margin-top: 10px;
    text-align: center;
    font-style: 16px;
    font-weight: 300;
  }