.filter-container {
    background-color: #f7f7f7;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
    padding: 10px 0 10px 15px;
    margin-bottom: 5px;
    width: 100%;
}

.filters, .date-range-selector-container {
    display: inline-block;
    margin: 0 10px;
}

.date-range-container {
    margin: 0 5px;
}

.search {
    display: inline;
    float: right;
    margin-right: 25px;
}