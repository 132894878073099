.search-wrapper {
    position: relative;
    margin: auto;
    margin-top: 5%;
    width: 450px;
    padding: 10px;
    background-color: #fff;
}

.search-loader {
    width: auto;
}

.search-button {
    cursor: pointer;
    color: #8b8b8b;
    padding: 5px;
    background-color: #fff;
    display: inline-block;
    width: 175px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
}

.search-button:hover {
    background-color: #f1f1f1;
}
.search-body {
    margin-top: 30px;
}
.search-input {
    cursor: pointer !important;
}

ul.result-list {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0 0 0 5px;
    max-height: 350px;
    overflow-x: scroll;
}

ul.result-list li {
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
}
ul.result-list li:hover {
    background-color: #f1f1f1;
}

ul.result-list li:last-child {
    border: none;
}

.result-list-alias, .result-list-iccid {
    padding-left: 5px;
}

.result-list-alias {
    font-weight: 500;
    color: black;
}

.result-list-iccid {
    font-weight: 200;
    color: #1c1c1c;
}
.no-matches-found {
    margin: 10px;
    font-weight: 300;
}
.close-btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
}