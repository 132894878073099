@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "flag-icon-css/css/flag-icon.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 6%;
  margin: 0;
}

:focus {
  outline: none !important;
}

.bp3-tab-panel {
  margin-top: 0 !important;
  border-top: 1px solid #f1f1f1 !important;
  border-right: 1px solid #f1f1f1 !important;
  padding: 25px !important;
}
.bp3-multi-select {
  max-width: 250px;
  max-height: 100px;
  overflow-y: scroll;
  min-width: 150px !important;
  margin: 0 5px;
}

.bp3-table-quadrant {
  background-color: #ffffff !important;
}

.bp3-table-selection-enabled .bp3-table-cell {
  cursor: default !important;
}
.bp3-tab-list {
  margin-left: 15px !important;
}
.bp3-table-container {
  box-shadow: none !important;
}

.pulse {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-animation 3s infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(0.97);
    box-shadow: 0 0 0 5px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.pending-status {
  cursor: progress;
}

.back-btn {
  vertical-align: top;
  margin: 18px 0 0 15px !important;
}