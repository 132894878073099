.dashboard-wrapper {
  margin: 0 auto;
  width: 1300px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 19%);
  background-color: #fff;
}
.empty-data-set-dashboard {
  margin: 20% 0;
}
.dashboard-username-avatar {
  float: right;
  margin: 10px 25px 0 0;
}

.dashboard-header,
.dashboard-sub-header {
  min-height: 75px;
  width: 1300px;
}

.dashboard-content {
  padding-left: 20px;
  padding-bottom: 75px;
}
.dashboard-footer {
  height: 60px;
  width: 1300px;
  clear: both;
  background-color: #282828;
}

.dashboard-tabs-container {
  margin: 50px 100px 0 0;
}

.dashboard-usage-total {
  font-size: 2.0em;
}
.dashboard-usage-label {
  display: block;
  font-size: 14px;
  font-weight: 300;
}

.dashboard-total-usage-summary {
  margin: 0 0 25px 0px;
  display: inline-block;
}

.dashboard-usage-data-item {
  float: left;
  margin: 10px;
  border-right: 1px solid #d0d0d0;
  padding: 0 15px 0px 0;
}

.dashboard-usage-data-item:last-child {
  margin-right: 0;
  border: none;
}

.dashboard-cards-container  {
  max-height: 540px;
  overflow-y: scroll;
}