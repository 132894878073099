hr {
  height: 1px;
  background-color: #ccc;
  border: none;
}
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.header-brand-logo {
  margin: 15px 0 5px 15px;
}
.sign-in-display-text {
  font-size: 48px;
  font-weight: 200;
  display: inline-block;
  margin-bottom: 10px;
}