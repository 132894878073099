.helper-display-b {
  display: block !important;
}

.helper-display-ib {
  display: inline-block !important;
}
.info-icon {
  margin-left: 5px;
  cursor:help;
}
.mo-mt-icon {
  margin: 2px 0px 5px 2px;
  display: inline-block;
}

.flex-container > div {
  padding: 8px;
}

.wrapper {
  margin: 0 auto;
  width: 1200px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 19%);
  background-color: #fff;
}

.header {
  height: 75px;
  width: 1200px;
}
.sub-header {
  width: 1200px;
}
.filter-wrapper {
  clear: both;
  display: block;
}

.header > div {
  display: inline-block;
  margin-top: 15px;
}

.username-avatar {
  float: right;
  margin-right: 25px;
}

.content {
  float: left;
  width: 700px;
  min-height: 800px;
  padding-bottom: 125px;
}

.rightcolumn {
  margin-top: 40px;
  padding: 25px;
  width: 500px;
  float: left;
  border-top: 1px solid #f1f1f1;
}

.footer {
  height: 60px;
  width: 1200px;
  clear: both;
  background-color: #282828;
}

.no-data-state {
  margin-top: 20%;
  padding-bottom: 31%;
}

.total-usage-summary-header {
  margin: 0 0 25px 0px;
  display: inline-block;
}

.usage-total-header {
  font-size: 46px;
}
.usage-total-header > span {
  font-size: 16px;
}
.usage-label-header {
  display: block;
  font-size: 16px;
  font-weight: 300;
}

.usage-data-container {
  margin-top: 10px;
}

.usage-data-item-header {
  float: left;
  margin: 10px;
}
.usage-data-item-header:last-child {
  margin-right: 0;
}

.general-sim-info-summary {
  margin: 0 0 10px 0px;
  display: inline-block;
}

.sim-summary-label {
  font-size: 16px;
}
.sim-summary-info-data {
  display: block;
  font-size: 12px;
  font-weight: 700;
}

.sim-data-item {
  padding: 15px 5px;
  min-width: 400px;
}
.sim-data-item:last-child {
  margin-right: 0;
}
.sim-usage-data-table-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  margin-top: 50px;
}

.health-indicator-action {
  float: right;
  margin-right:25px
}

.alias-edit-input {
  display: inline-block !important;
}
.alias-edit-save-btn,
.alias-edit-cancel-btn {
  margin: 0 2px;
}

.alias{
  margin-left: -11px;
}

.call-type-icon {
  margin: 10px;
}

.country-name {
  margin-left: 5px;
}

.dialog-msg {
  width: 300px;
}
