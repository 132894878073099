.pagination-container {
    display: inline-block;
    margin: 25px 10px;
    width: 100%;
}

.pagintion-page-size-container {
    display: inline-block;
}

.pagination-controls-container {
    margin-right:25px;
    float:right;
}
.page-btn {
    margin: 0 2px;
}
.jump-to-input {
    display: inline-block;
   
}

.jump-to-container{
    margin-left: 10px;
}
.jump-to{
    width: 75px !important;
}