[data-initials]:before {
  background: #0f9960;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.logout-wrapper {
    vertical-align: middle;
    padding-bottom: 4px;
    display: inline-block;
    margin-left:5px;
}

.username {
  font-weight: 700;
  cursor: pointer;
  padding-left: 2px;
}
