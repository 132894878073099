.reset-password-wrapper {
  width: 350px;
}

.password-strength-bar {
  margin: 10px 0 5px 0;
  display: inline-block;
  width: 300px;
}

.new-password-field {
  width: 310px;
}

.btn-container {
  display: flex;
  justify-content: center;
}
.btn-container button {
  margin: 10px 5px;
}
