.qr-code-fields-container {
    margin-bottom: 50px;
}
.qr-code-container {
    margin-bottom: 25px;
}
.qr-code {
    width: 225px !important;
    height: 225px !important;
    margin-bottom: 5px;
    margin-left: -20px;
}

.download-qr-code-btn {
    float: right;
    margin-right:15px;
}
.qr-label {
    font-size: 12px !important;
}
.qr-callout {
    margin-bottom: 50px;
}